import React from 'react'

import AuthProvider from 'contexts/AuthProvider'
import UserProvider from 'contexts/UserProvider'
import Layout from 'components/Layout'
import LinkBack from 'components/LinkBack'

import { useCookieSettingsPage } from 'hooks'

export default function CookieSettingsPage() {
  const {
    switchIsEnabled,
    handleSwitchChange,
    handleSave,
    saveButtonIsDisabled,
    isSaved,
  } = useCookieSettingsPage()

  return (
    <AuthProvider>
      <UserProvider>
        <Layout>
          <div className="row">
            <div className="col-lg-2">
              <LinkBack>&#8592; Back</LinkBack>
            </div>
            <div className="col-lg-8">
              <h1>Cookie Settings</h1>
              <p>
                We only use cookies to improve our site, measure performance,
                understand our audience, and enhance your&nbsp;experience.
                We&nbsp;do not collect cookies for advertising&nbsp;purposes.
              </p>
              <p>
                If you still wish to disable and delete all&nbsp;cookies,
                you&nbsp;may do so&nbsp;below.
              </p>
              <div className="form-check form-switch">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="cookie-switch"
                  name="cookie-switch"
                  checked={switchIsEnabled}
                  onChange={handleSwitchChange}
                />
                <label className="form-check-label" htmlFor="cookie-switch">
                  Allow Cookies
                </label>
              </div>
              <button
                className="btn btn-primary mt-4"
                disabled={saveButtonIsDisabled}
                onClick={handleSave}
              >
                {isSaved ? 'Saved' : 'Save changes'}
              </button>
            </div>
          </div>
        </Layout>
      </UserProvider>
    </AuthProvider>
  )
}
